import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CampaignModel } from '../models/CampaignModel';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService  extends BaseService {
  apiUrl = '/api/campaign';

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAll() {
    return this.httpClient.get<CampaignModel[]>(
      this.baseUrl + this.apiUrl + '/GetAll',
      this.getAuthHeader()
    );
  }
  getActiveCampaign() {
    return this.httpClient.get<CampaignModel>(
      this.baseUrl + this.apiUrl + '/GetActiveCampaign',
      this.getAuthHeader()
    );
  }
  CheckCampaignName(CampName:string) {
    return this.httpClient.get<boolean>(
      this.baseUrl + this.apiUrl + '/CheckCampaignName?CampName='+CampName,
      this.getAuthHeader()
    );
  }

  EditCamp( model:CampaignModel) {
    return this.httpClient.post(
      this.baseUrl + this.apiUrl + '/EditCampaign',model,
      this.getAuthHeader()
    );
  }
  
}
