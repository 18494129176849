import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataModel, EligibleTraderModel } from '../models/EligibleTraderModel';
import { BulkFileResultModel } from '../models/BulkFileResultModel';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { configurationModel } from '../models/configurationModel';

@Injectable({
  providedIn: 'root'
})
export class TraderCrmService extends BaseService {
  apiUrl = '/api/Crm/';
  constructor(private httpClient: HttpClient) {
    super();
  }
  getAllTrader(pageNumber: number, pageSize: number, searchTerm: string = '') {

    return this.httpClient.get<DataModel<EligibleTraderModel[]>>(
      this.baseUrl + this.apiUrl + 'GetAllEligibleTrader?pageNumber=' + pageNumber + "&pageSize=" + pageSize + "&searchTerm=" + searchTerm,
      this.getAuthHeader()
    );
  }

  DeleteTrader(msisdn) {
    return this.httpClient.get<boolean>(
      this.baseUrl + this.apiUrl + 'DeleteEligibleTrader?Msisdn=' + msisdn,
      this.getAuthHeader()
    );
  }
  uploadBulkFile(file: FormData) {
    return this.httpClient.post<BulkFileResultModel>(
      this.baseUrl + this.apiUrl + 'upload',
      file,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.token,
          'accept-language': 'ar'
        })
      }
    );
  }
  DownloadResultFile(fileName)  {
    return this.httpClient.get(this.baseUrl + this.apiUrl + 'DownloadFile?fileName='+fileName,{observe:'response',responseType: 'blob',headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.token,
    }) });
  }

  getnewAuthHeader() {
    return {
      observe:'response',responseType: 'blob',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.token,
      })
    };
  }
  GetConfigs(): Observable<configurationModel[]> {
    return this.httpClient.get<configurationModel[]>(this.baseUrl + this.apiUrl + 'GetConfigurations',
    this.getAuthHeader());
  }

  updateConfigurations(configurationModel:configurationModel[]) {
    return this.httpClient.post<configurationModel[]>(this.baseUrl + this.apiUrl + 'UpdateConfigurations',
    configurationModel,
    this.getAuthHeader()
    );
  }
}
