export class UpdateProfileModel {
    id: number;
    name: string;
    email: string;
    cityId:number;
    areaId:number;
address:string;
shopName:string;
haveSmartPhone!:boolean;
token: string;
mobileNumber:string;
    
  }