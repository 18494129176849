import { Component, OnInit } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { WalletSummaryModel } from '../models/walletSummaryModel';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { UpdateProfileModel } from '../models/updateProfileModel';
import { city } from '../models/CitiesModel';
import { CampaignService } from '../services/campaign.service';
import { CampaignModel } from '../models/CampaignModel';
import { ListData } from '../models/ListData';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  constructor(public settingService: SettingService, public accountService: AccountService, 
    public router: Router,public campaignService:CampaignService,private modalService: NgbModal
    ) { 
    this.settingService.pageName='updateProfile';
  }

closeResult = '';


walletSummary:WalletSummaryModel=new WalletSummaryModel();
 profile=new UpdateProfileModel();
 citiesList:ListData[];
 areas:ListData[];
 cities:string[];
 shopName:boolean=false;
 haveSmartPhone:boolean=false;
  ngOnInit() {
   this.campaignService.getActiveCampaign().subscribe(res=>{
     if(res != null){
      this.shopName=   res.campaignFields.find(d=> d.fieldName == 'ShopName').visibility;
      this.haveSmartPhone=   res.campaignFields.find(d=> d.fieldName == 'HaveSmartPhone').visibility;
     }
     });

    this.accountService.getProfileData(this.accountService.getUserMobileNumber()).subscribe(a=>{
     this.profile=a;
     this.getCities();
     this.GetAreasByCityId(a.cityId);
    });
  }
  getCities(){
    this.accountService.GetCitiesList().subscribe(res=>{
       this.citiesList=res;
      });
    }
    GetAreasByCityId(id:number){
      this.accountService.GetAreasByCityId(id).subscribe(res=>{
         this.areas=res;
        });
      }

  changeProfile()
  {

    this.profile.token=localStorage.Registertoken;
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.profile.mobileNumber=this.accountService.getUserMobileNumber();
    this.accountService.updateProfile(this.profile).subscribe(res => {
     if(res.errorCode==0)
     {
      this.settingService.isError = false;
    this.settingService.walletSummary['source']['_value'].name=this.profile.name;
    this.settingService.walletSummary['source']['_value'].email=this.profile.email;
    this.settingService.loading = false;

      this.router.navigate(['home']);
     } else
     {
      this.settingService.isError = false;
       this.settingService.message=res.errorDesc;
     }
    });
  }

  deleteAccount(){
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.accountService.deleteAccount(localStorage.Registertoken).subscribe(result=>{
      if (result.errorCode == 0) {
        this.settingService.loading = false;
        this.settingService.isError = false;
        this.router.navigate(['login']);
      } else {
        this.settingService.loading = false;
        this.settingService.isError = true;
        this.settingService.message = result.errorDesc;
      }
    });
  }

  openConFirmDelete(deleteContent) {
    this.modalService.open(deleteContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
