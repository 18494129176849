import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Transaction } from '../models/transaction';
import { VoucherDetails } from '../models/voucherDetails';
import { BulkResponseModel } from '../models/bulkResponseModel';
import { BulkCouponModel } from '../models/bulkCouponModel';
import { ExpiryPoints } from '../models/ExpiryPoints';

@Injectable({
    providedIn: 'root'
  })
  export class TransactionService extends BaseService {
    apiUrl = '/api/transaction/';
    constructor(private httpClient: HttpClient) {
      super();
    }
    getAllTransactions(walletId: number, pageNumber:number, pageSize:number) {
      return this.httpClient.get<Transaction[]>(
        this.baseUrl + this.apiUrl + 'getalltransactions'+
        '?walletId='+ walletId+
        '&pageNumber='+pageNumber+
        '&pageSize='+pageSize,
        this.getAuthHeader()
      );
    }
    getAllExpiryPoints(walletId: number,days:Number ) {
      return this.httpClient.get<ExpiryPoints[]>(
        this.baseUrl + this.apiUrl + 'GetPointsToBeExpired'+
        '?walletId='+ walletId+'&days='+days,
        this.getAuthHeader()
      );
    }
    getAllOffers(msisdn: string) {
      return this.httpClient.get<VoucherDetails[]>(
        this.baseUrl + this.apiUrl + 'getalloffers'+
        '?msisdn='+ msisdn ,
        this.getAuthHeader()
      );
    }

    burnBulkVouchers(bulkCouponModel: BulkCouponModel)
    {
      const headerDict = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token,
        'accept-language': 'ar',
        'MSISDN':bulkCouponModel.msisdn
      }
      
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      };
     
      return this.httpClient.post<BulkResponseModel[]>(
        this.baseUrl +
          this.apiUrl +'/BurnBulkVouchers',bulkCouponModel,
          requestOptions
      );
    }
}