import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Transaction } from '../models/transaction';
import { TierModel } from '../models/tierModel';
import { WalletSummaryModel } from '../models/walletSummaryModel';

@Injectable({
    providedIn: 'root'
  })
  export class TierService extends BaseService {
    apiUrl = '/api/tier/';
    constructor(private httpClient: HttpClient) {
      super();
    }
    getAllTiers() {
        return this.httpClient.get<TierModel[]>(
          this.baseUrl + this.apiUrl + 'getAllTiers' ,
          this.getAuthHeader()
        );
      }
      getWalletSammary(msisdn: string) {

        return this.httpClient.get<WalletSummaryModel>(
          this.baseUrl + this.apiUrl + 'GetWalletSummaryPerPortal'+
          '?msisdn='+ msisdn ,
          this.getAuthHeader()

        );
      }
}