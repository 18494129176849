import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { TierService } from '../services/tier.service';
import { TierModel } from '../models/tierModel';
import { WalletSummaryModel } from '../models/walletSummaryModel';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  closeResult: string;
  tiersLst: TierModel[]=[];
  summary=new WalletSummaryModel();
  tillPoints: number=0;
  tillTier:string="";

  constructor(public settingService: SettingService, public accountService: AccountService, public router :Router,
    private tierService: TierService) { }

  
 

  ngOnInit() {
    this.settingService.getUserInfoFromToken(localStorage.token);
    //this.getTiers();
    //this.getSummary();
  }

  getTiers()
  {
    this.tierService.getAllTiers().subscribe(res=>{
       this.tiersLst=res;
       this.getSummary();
    });
  }

  getSummary()
  {
    this.tierService.getWalletSammary(this.settingService.user.mobileNumber).subscribe(res=>{
      localStorage.walletId=res.walletId;
       this.summary=res;
      this.settingService.setWalletSummary(res);
       //get next tier name and points
       var tierName= this.tiersLst.filter(a=>a.name==this.summary.tierName)[0];
       if(tierName==null||tierName==undefined)
       {
         var lastTier=this.tiersLst[this.tiersLst.length-1]
         this.tillPoints=+lastTier.points-this.summary.totalPoints;
         this.tillTier=lastTier.name;
       }
       else
       {
         var currentindex=this.tiersLst.findIndex(a=>a.name== tierName.name);
         var firstTier=this.tiersLst[currentindex-1];
         if(firstTier!=null && firstTier!=undefined)
         {
          this.tillPoints=+firstTier.points-this.summary.totalPoints;
          this.tillTier=firstTier.name;
         }
         else
         {
           this.tillTier="";
           this.tillPoints=0;
         }
       
       }
    });
  }
}
