import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SettingService } from '../setting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector,private ngZone: NgZone) {}
  handleError(error: any) {
    const router = this.injector.get(Router);
    const settingService = this.injector.get(SettingService);
    const  modalService = this.injector.get(NgbModal); 

    console.log('URL: ' + router.url);
    console.error('An error occurred:', error.message);
    if (error.status === 401) {
      router.navigate(['/login']);
     // modalService.dismissAll();

    }

    try {
    
      // settingService.loading = false;
      // setTimeout( c =>  settingService.isError = true );
      // setTimeout( c => settingService.message = error.error ? error.error.message : '')
      this.ngZone.run( ()=> {
        settingService.loading = false;
       settingService.isError = true ;
       settingService.message = error.error ? error.error.message : '';
      })

      if (error.status===409) {
        this.ngZone.run( ()=> {
          settingService.loading = false;
         settingService.isError = true ;
         settingService.message = error ? error.error : '';
        })
      }
      if (error.status === 500) {
        settingService.showErrorPopup();
      }
    } catch {
      router.navigate(['/login']);
    }
  }
}
