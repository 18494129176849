import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Partner } from '../models/partner';
import { Category } from '../models/category';
import { PartnerDetails } from '../models/partnerDetails';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends BaseService {
  apiUrl = '/api/partner/';
  constructor(private httpClient: HttpClient) {
    super();
  }
  getAll() {
    return this.httpClient.get<Partner[]>(
      this.baseUrl + this.apiUrl + 'GetCouponzPartners',
      this.getAuthHeader()
    );
  }
  // getCategories() {
  //   return this.httpClient.get<Category[]>(
  //     this.baseUrl + this.apiUrl + 'getcategories',
  //     this.getAuthHeader()
  //   );
  // }
  // getPartnerById(id: number, categoryId: number, getRelated: boolean) {
  //   return this.httpClient.get<PartnerDetails>(
  //     this.baseUrl +
  //       this.apiUrl +
  //       'getPartnerById?id=' +
  //       id +
  //       '&categoryId=' +
  //       categoryId +
  //       '&getRelated=' +
  //       getRelated,
  //     this.getAuthHeader()
  //   );
  // }
}
