
export class CampaignModel{
   
    id:number| null;
    campaignName:string;
    active:boolean;
    createDate:string;
    endDate:string;
    campaignFields:CampaignFields[];
}

export class CampaignFields{
    
    id:number| null;
    fieldName:string;
    displayName:string;

    visibility:boolean;
    bonusValue:number;
    campId:number;
}