import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { CampaignModel } from '../models/CampaignModel';
import { CampaignService } from '../services/campaign.service';
import { SettingService } from '../services/setting.service';


@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {

  closeResult = '';
  allCampaign: CampaignModel[] = [];
  pageNumber=1;
  totalItems=1;
  page = 1;
  pageSize=10;
  Campaign=new CampaignModel();
  newCamp;
  AddFlag=true;
  loading=false;
  current:string;
  currentpage=1;
   checkFieldVisible=false;
   existName:boolean=false;
  constructor(public datepipe: DatePipe,private modalService: NgbModal,private CampaignService: CampaignService,public settingService: SettingService) {
    this.settingService.pageName='Campaign';
    this.current=this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }
    ngOnInit() {
      this.getAll();
  }

  defualtCampaign()
   {
      this.newCamp =new CampaignModel();
      this.newCamp.campaignFields=[{displayName:"Oil Shop Name",fieldName:"ShopName",bonusValue:0,visibility:false,campId:0,id:0},{displayName:"Smart Phone",fieldName:"HaveSmartPhone",bonusValue:0,visibility:false,campId:0,id:0}];
    return this.newCamp;
}

  getAll() {
    this.totalItems=0;
    this.settingService.loading = true;
    this.CampaignService.getAll().subscribe(res => {
      this.allCampaign = res;
     this.totalItems=res!=null? res.length : 0;
      this.settingService.loading = false;
    });
    
  }
  CheckName(event) {
    if(event != null){
      this.CampaignService.CheckCampaignName(event).subscribe(res=>
        {
          this.existName=res;
        })
    }
    
  }
  disableDate(){
    return false;
} 

  open(content,rowEdit,add:boolean) {
    this.settingService.message = null;
    this.settingService.isError = false;
    if(!add){
      this.AddFlag=false;
      this.Campaign =rowEdit;
    }
    else{
      this.AddFlag=true;
      this.Campaign=  this.defualtCampaign();
    }
    this.Campaign.endDate= this.datepipe.transform(this.Campaign.endDate, 'yyyy-MM-dd');
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    /* vendors contains the element we're looking for */
  
  open2(content2,Campaign) {
    this.settingService.loading = true;
    this.loading=true;
    this.CampaignService.EditCamp(Campaign).subscribe(res => {
      if (res){
        this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title',size: 'sm', centered: true}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
       this.getAll();
      }
      this.Campaign=this.defualtCampaign();
      this.loading=false;

    },error=>{
      this.loading = false;
      this.settingService.isError = true;
      this.settingService.message = error.error.message;
      var element = <HTMLButtonElement>document.getElementById("btnSubmit");
      element.disabled = false;
    }
    );
 this.settingService.loading = false;
  }

  updateStatus(content2) {
  if(!this.AddFlag){
        this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title',size: 'sm', centered: true}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
  }

checkFieldsVisible(){
  this.checkFieldVisible=false;

  if ( this.Campaign.campaignFields.filter(e => e.visibility == true).length > 0) {
    this.checkFieldVisible=true;

  }
}
  close(){
    this.modalService.dismissAll();

  }
  private getDismissReason(reason: any): string {

    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 
  fromModel(date: Date): NgbDateStruct {
    return date ? {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    } : null;
  }

  toModel(date: NgbDateStruct): Date {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }


}
