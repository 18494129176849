import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public baseUrl = environment.baseUrlConfig || location.origin;
  getAuthHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.token,
        'accept-language': 'ar'
      })
    };
  }
  constructor() {}
}
