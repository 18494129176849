import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { ValidationResponse } from '../models/validationResponse';
import { SettingService } from '../services/setting.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators'
import { RegisterModel } from '../models/registerModel';
import { LogInModel } from '../models/logInModel';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userData: string;
  isVerified = false;
  mobileExist = false;
  resend: boolean;
  otp: string="";
  resendTimer=0;
  interval;
  register=false;
  active=1;
  registerModel: RegisterModel= new RegisterModel();
  logInModel: LogInModel= new LogInModel();
  namePattern:string=".*[^A-Z a-z ء-ي].*";

  constructor(
    private accountService: AccountService,
    public settingService: SettingService,
    private router: Router,
    private _localStorageService: LocalStorageServiceEncrypt,
    private modalService:NgbModal
  ) {}


  keyPress(event: any) {
    const pattern = /.*[^A-Z a-z ء-ي].*/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  ngOnInit() {
    this.settingService.loading = false;
    this.isVerified = false;
    this.resend = false;
    this.settingService.isError = false;
    this.accountService.signOut();
    this.modalService.dismissAll();
  }
  validateMobileNumber(mobileNumber: string) {
    this.isVerified = false;
    this.resend = false;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.settingService.loading = true;
    this.accountService.validateMobileNumber(mobileNumber).subscribe(res => {
      this.userData = mobileNumber;
      this.settingService.loading = false;
      this.settingService.isError = false;
      this.isVerified = true;
      this.resendTimer=+res;
      this.startTimer();
    });
  }
  verifyOtp() {
    if(this.otp.length<4)
    {
      return;
    }
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.accountService.verifyOtp(this.userData, this.otp).subscribe(res => {
      localStorage.Registertoken = res;
      clearInterval(this.interval);
      this.settingService.loading = false;
      this.register=true;
    });
  }
  createAccount()
  {
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.registerModel.mobileNumber=this.userData;
    this.registerModel.token=localStorage.Registertoken;
    console.log(this.registerModel);
    this.accountService.createAccount(this.registerModel).subscribe(res => {
     if(res)
     {
      this.settingService.loading = false;
      this.settingService.isError = false;
      //localStorage.Registertoken = res;
     // localStorage.token = res;
      this.isVerified = false;
      this.mobileExist = false;
      this.register=false;
      this.active=1;
      this.accountService.signOut();
      this.router.navigate(['/login']);
     } else
     {
      console.log(res.errorDesc);
      this.settingService.loading = false;
      this.settingService.isError = false;
       this.settingService.message=res.errorDesc;
     }

    });
  }

  onOtpChange(event){
    this.otp=event;
  }
  startTimer() {
    this.interval = setInterval(() => {
      if(this.resendTimer==1)
      {
        clearInterval(this.interval);
        this.resend = true;
      }
      this.resendTimer--;
    },1000)
  }


  logIn(numberInvalid,passwordInvalid)
  {
    if(numberInvalid||passwordInvalid)
    {
      return;
    }
    this.settingService.isError = false;
    this.settingService.loading = true;
    this.accountService.logIn(this.logInModel).subscribe(res => {
      localStorage.token = res.token;
      localStorage.Registertoken=res.registerToken;
      this.userData = this.logInModel.userName;
      this.settingService.loading = false;
      this.settingService.isError = false;
      this.accountService.checkDisclaimer(this.logInModel.userName,false).subscribe(a=>{
        this._localStorageService.set('AcceptTerms',a);
        this.settingService.setAcceptTerms(a);
      });
      this.router.navigate(['home']);

    });
  }

  onNavChanged(changeEvent: NgbNavChangeEvent){
    this.settingService.isError = false;
    this.settingService.message = null;
  }
}
