import {Country} from './country';

export const COUNTRIES: Country[] = [
  {
    id: 1,
    name: 'Russia',
    area: 17075200,
    population: 146989754
  },
  {
    id: 2,
    name: 'France',
    area: 640679,
    population: 64979548
  },
  {
    id: 3,
    name: 'Germany',
    area: 357114,
    population: 82114224
  },
  {
    id: 4,
    name: 'Portugal',
    area: 92090,
    population: 10329506
  },
  {
    id: 5,
    name: 'Canada',
    area: 9976140,
    population: 36624199
  },
  {
    id: 6,
    name: 'Vietnam',
    area: 331212,
    population: 95540800
  },
  {
    id: 7,
    name: 'Brazil',
    area: 8515767,
    population: 209288278
  },
  {
    id: 8,
    name: 'Mexico',
    area: 1964375,
    population: 129163276
  },
  {
    id: 9,
    name: 'United States',
    area: 9629091,
    population: 324459463
  },
  {
    id: 10,
    name: 'India',
    area: 3287263,
    population: 1324171354
  },
  {
    id: 11,
    name: 'Indonesia',
    area: 1910931,
    population: 263991379
  },
  {
    id: 12,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 13,
    name: 'China',
    area: 9596960,
    population: 1409517397
  },
  {
    id: 14,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 15,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 16,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 17,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 18,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 19,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  },
  {
    id: 20,
    name: 'Tuvalu',
    area: 26,
    population: 11097
  }
];
