import { Injectable, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from './base.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonMessage } from '../models/commonMessage';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';
import { WalletSummaryModel } from '../models/walletSummaryModel';
@Injectable({
  providedIn: 'root'
})
export class SettingService {
  @Output() languageEvent = new EventEmitter();
  private categoryName = new BehaviorSubject('');
  currentcategory = this.categoryName.asObservable();
  private voucherModal = new BehaviorSubject(false);
  voucherModalOpsevable = this.voucherModal.asObservable();
  private AcceptTerms = new BehaviorSubject(false);
  currentAcceptTerms = this.AcceptTerms.asObservable();
  private Summary = new BehaviorSubject(new WalletSummaryModel());
  
  walletSummary = this.Summary.asObservable();
  private errorName = new BehaviorSubject('');
  currentError = this.errorName.asObservable();
  isError = false;
  message = '';
  lang = 'ar';
  pageName='home';
  showLogOut: boolean;
  public loading: boolean;
  public user: User;
  constructor(private translate: TranslateService) {
    console.log()
    this.lang = localStorage.lang || 'ar';
    this.useLanguage('ar');

    if (localStorage.getItem('token')) {
      this.getUserInfoFromToken(localStorage.token);
    }
  }
  useLanguage(language: string) {
    this.lang = language;
    localStorage.lang = language;
    document.dir = language === 'ar' ? 'rtl' : 'rtl';
    this.translate.use(language);
    this.languageEvent.emit('');
  }
  getUserInfoFromToken(token: string) {
    const jwtHelper = new JwtHelperService();
    const data = jwtHelper.decodeToken(token) as User;
    this.user = data;
    // console.log(this.user);
    localStorage.mobileNumber=this.user.mobileNumber;
  }
  showErrorPopup() {
    document.getElementById('errorMessage').click();
  }
  changeCategory(category: string) {
    this.categoryName.next(category)
  }
  openVoucherModal(open: boolean) {
    this.voucherModal.next(open)
  }
  setWalletSummary(summary: WalletSummaryModel) {
    this.Summary.next(summary);
  }
  setError(error: string) {
    this.errorName.next(error)
  }
  setAcceptTerms(accept: boolean) {
    this.AcceptTerms.next(accept);
  }
}
