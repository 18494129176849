import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnersDetailsComponent } from './partners-details/partners-details.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule,NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputModule } from  'ng-otp-input';

import {
  TranslateLoader,
  TranslateService,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { GlobalErrorHandlerService } from './services/error-handler/global-error-handler.service';
import { FormsModule } from '@angular/forms';
import { HttpHeaderInterceptorService } from './services/http-interceptor/http-header-interceptor.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateVoucherComponent } from './create-voucher/create-voucher.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { ForegtPasswordComponent } from './foregt-password/foregt-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { LocalStorageModule } from 'angular-2-local-storage-encrypt';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpeedoMeterComponent } from './speedo-meter/speedo-meter.component';
import { KycComponent } from './kyc/kyc.component';
import { DatePipe } from '@angular/common';
import { TraderCrmComponent } from './trader-crm/trader-crm.component';
import {NgbdSortableHeader} from './trader-crm/sortable.directive';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PartnersComponent,
    PartnersDetailsComponent,
    NavbarComponent,
    FooterComponent,
    TermsConditionsComponent,
    TransactionsComponent,
    SidebarComponent,
    CreateVoucherComponent,
    AboutComponent,
    FaqComponent,
    ForegtPasswordComponent,
    ChangePasswordComponent,
    UpdateProfileComponent,
    SpeedoMeterComponent,
    KycComponent,
    TraderCrmComponent,
    NgbdSortableHeader,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    NgOtpInputModule,
    NgbPaginationModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LocalStorageModule.forRoot({
      prefix: 'my-app',
      storageType: 'localStorage',
      encryptionActive: true,
      encryptionOptions: {
          encryptionKey: 'keyForEncriptHere',
          encryptionIv: 'iVHere',
          encryptionSalt: 'saltHere'
      }
  })
  ],
  providers: [
    TranslateService,
    DatePipe,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    }
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpHeaderInterceptorService,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
