import { Component, OnInit } from '@angular/core';
import { RegisterModel } from '../models/registerModel';
import { SettingService } from '../services/setting.service';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { ChangePasswordModel } from '../models/changePasswordModel';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(public settingService: SettingService, public accountService: AccountService,public router: Router) { 
    this.settingService.pageName='changePassword';
  }
  resetpassword: ChangePasswordModel=new ChangePasswordModel();

  ngOnInit() {
  }
  changePassword()
  {
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.resetpassword.token=localStorage.Registertoken;
    console.log(this.resetpassword);
    this.accountService.changePassword(this.resetpassword).subscribe(res => {
     if(res.errorCode==0)
     {
      this.settingService.loading = false;
      this.settingService.isError = false;
      this.router.navigate(['login']);
     } else
     {
      this.settingService.isError = false;
       this.settingService.message=res.errorDesc;
     }

    });
  }
}
