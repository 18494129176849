import { Component} from '@angular/core';
import { TierModel } from '../models/tierModel';
import { WalletSummaryModel } from '../models/walletSummaryModel';
import { SettingService } from '../services/setting.service';
import { TierService } from '../services/tier.service';

@Component({
  selector: 'speedo-meter-app',
  templateUrl: './speedo-meter.component.html',
  styleUrls: [ './speedo-meter.component.scss' ]
})
export class SpeedoMeterComponent {
  public speedScore:number = 0; //min : 0; max: 100
  public readingSpeed:number;
  public niddleSpeed:number;
  walletSummary: WalletSummaryModel=new WalletSummaryModel();
  tiersLst: TierModel[]=[];
  tillPoints: number=0;
  tillTier:string="";
  walletExpiryDate:string;

  constructor(public settingService: SettingService, private tierService: TierService) { }
  ngOnInit() {


    this.settingService.getUserInfoFromToken(localStorage.token);
    this.settingService.walletSummary.subscribe(a=>{
      this.walletExpiryDate=new Date(a.expiryDate).toLocaleDateString('ar-EG', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

      this.walletSummary=a;
      if(this.walletSummary.tierPoints != undefined)
      {
        this.getTiers();
      }
   });
 
  }
  updateSpeed(){
    this.readingSpeed = Math.round(this.speedScore * 180/100)-45;
    this.niddleSpeed = Math.round(this.speedScore * 180/100)-90;
  }

  getSpeed(speed){
    this.speedScore = speed;
    this.updateSpeed();
  }
  getTiers()
  {
    this.tierService.getAllTiers().subscribe(res=>{
     var zeroTier: TierModel={id:'0',name:'0',points:'0',offerNumber:'0'}
      res.push(zeroTier);
       this.tiersLst=res.sort((a, b) => (a.points > b.points) ? 1 : -1);
       var tierName= this.tiersLst.filter(a=>a.name==this.walletSummary.tierName)[0];
       if(tierName==null||tierName==undefined)
       {
         var firstTier=this.tiersLst[1]
         this.tillPoints=+firstTier.points-this.walletSummary.totalPoints;
         this.tillTier=firstTier.name;
       }
       else
       {
         var currentindex=this.tiersLst.findIndex(a=>a.name== tierName.name);
         var nextTier=this.tiersLst[currentindex+1];
         if(nextTier!=null && nextTier!=undefined)
         {
          this.tillPoints=(+nextTier.points)-this.walletSummary.totalPoints;
          this.tillTier=nextTier.name;
         }
         else
         {
           this.tillTier="";
           this.tillPoints=0;
         }
       
       }
       this.calculateMeter();

    });
  }

  calculateMeter()
  {
      if(this.tillPoints==0){
        this.getSpeed(100);
        return;
      }
      var userPoints=this.walletSummary.totalPoints < 0 ? 0 : this.walletSummary.totalPoints;
      //devide the meter to tiers Length
      var MeterSize=100/(this.tiersLst.length-1);
      var currentTier= this.tiersLst.filter(a =>a.name==this.walletSummary.tierName)[0]; 
      if(currentTier==null || currentTier ==undefined)
           currentTier=this.tiersLst[0];
      var currentTierIndex=this.tiersLst.indexOf(currentTier);
      var meterPerTier= currentTierIndex * MeterSize;
      var nextTier=this.tiersLst[currentTierIndex+1];
      var sizeBetweenTiers= +nextTier.points - (+currentTier.points);
      var PointsOnCurrentTier= userPoints - +currentTier.points ;
      if(PointsOnCurrentTier==0)
           PointsOnCurrentTier=1;
      var PercentageOnTier=(PointsOnCurrentTier / sizeBetweenTiers) * 100;
      var lastMeter=(PercentageOnTier*MeterSize / 100)+meterPerTier;
      this.getSpeed(lastMeter);
  }
}