import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnersDetailsComponent } from './partners-details/partners-details.component';
import { AuthGuardService } from './services/guard/auth-guard.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { ForegtPasswordComponent } from './foregt-password/foregt-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { KycComponent } from './kyc/kyc.component';
import { TraderCrmComponent } from './trader-crm/trader-crm.component';

const routes: Routes = [
    {
       path: '',
       component: HomeComponent,
       canActivate: [AuthGuardService]
    },

    { 
      path: 'home',
      component: HomeComponent,
      canActivate: [AuthGuardService]
    },

    {
      path: 'login', 
      component: LoginComponent
    },

    { 
      path: 'terms-conditions',
      component: TermsConditionsComponent,
      // canActivate: [AuthGuardService]
    },

    { 
      path: 'about',
      component: AboutComponent
    },

    { 
      path: 'faq',
      component: FaqComponent,
      // canActivate: [AuthGuardService]
    },


    {
      path: 'partners',
      component: PartnersComponent,
      canActivate: [AuthGuardService],
      data: { breadcrumb: 'partners' }
    },

    {
      path: 'partner-details',
      component: PartnersDetailsComponent,
      canActivate: [AuthGuardService],
      data: { breadcrumb: 'partner details' }
    },

   {
      path: 'transactions',
      component: TransactionsComponent,
      canActivate: [AuthGuardService],
      data: { breadcrumb: 'transactions' }
    },
    { 
      path: 'forgetpassword',
      component: ForegtPasswordComponent
    },
    {
      path: 'changepassword',
      component: ChangePasswordComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'updateprofile',
      component: UpdateProfileComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'kyc',
      component: KycComponent,
    },
    {
      path: 'trader-crm',
      component: TraderCrmComponent,
    }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
