export class WalletSummaryModel {
    walletId: string;
    totalPoints: number;
    lifeTimePoints: number;
    totalTransactions: number;
    reedemPoints: number;
    segmentPoints: number;
    redeemValue: number;
    tierName: string;
    tierPoints: number;
    name: string;
    email: string;
    pointsToExpire:number;
    expiryDate:Date;
  
  }
