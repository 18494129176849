import { Component, OnInit } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { TransactionService } from '../services/transactions.service';
import { Transaction } from '../models/transaction';
import { VoucherDetails } from '../models/voucherDetails';
import { ExpiryPoints } from '../models/ExpiryPoints';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  constructor(
    public settingService: SettingService,
    public transactionService: TransactionService
  ) {   this.settingService.pageName='transactions'; }
  allTransactions: Transaction[] = [];
  pageTransactions:Transaction[] = [];
  allOffers: VoucherDetails[] = [];
  ExpiryPointsList: ExpiryPoints[] = [];

  pageOffers:VoucherDetails[] = [];
  totalItems=0;
  currentpage = 1;
  pageSize=10;
  previousPage: any;
  transactionFlag=true;
  active=1;
  ngOnInit() {
   this.getAllTransactions();
   this.reload();
  }
  loadPage(page: number) {
    if (page !== this.currentpage) {
      this.currentpage = page;
      
      this.getPage();
    }
  }
  getAllTransactions() {
    this.settingService.loading = true;
   // this.transactionFlag=true;
    this.currentpage=1;
    this.totalItems=0;
    this.transactionService.getAllTransactions(localStorage.walletId,this.currentpage,this.pageSize).subscribe(res => {
       this.pageTransactions = res;
       this.pageTransactions.map(item => {
         item.dateString=new Date(item.creationDate).toLocaleDateString('ar-EG', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        return item});

     // this.pageTransactions.push.apply(this.pageTransactions,res);
       this.totalItems = res[0].allTransactionsCount;
      // this.pageTransactions = this.allTransactions.slice(0, this.pageSize);
      // this.getPage();
      this.settingService.loading = false;
    });
  }
  getPage() {
    if(this.transactionFlag==true)
    {
      this.getAllTransactions();
    }
    else 
    {
      this.pageOffers = this.allOffers.slice(this.pageSize*(this.currentpage-1), this.pageSize*this.currentpage);
    }

  }
  getAllOffers() {
    this.settingService.loading = true;
    this.transactionFlag=false;
    this.currentpage=1;
    this.totalItems=0;
    this.transactionService.getAllOffers(this.settingService.user.mobileNumber).subscribe(res => {
       this.allOffers = res;
       this.totalItems = res.length;
       this.pageOffers = this.allOffers.slice(0, this.pageSize);
       this.getPage();
      this.settingService.loading = false;
    });
  }
  getAllExpiryPoints() {
    const days=30;
    this.currentpage=1;
    this.totalItems=0;
    this.settingService.loading = true;
    this.transactionService.getAllExpiryPoints(localStorage.walletId,days).subscribe(res => {
     this.ExpiryPointsList = res;
     this.ExpiryPointsList.map(item => {
      item.dateString=new Date(item.expiryDate).toLocaleDateString('ar-EG', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
     return item});
     this.totalItems=this.ExpiryPointsList.length;
      this.settingService.loading = false;
    }, error => {
      this.settingService.loading = false;

    },
    );
  }
  reload() {
    this.settingService.languageEvent.subscribe(res => {
      if(this.transactionFlag==true)
    {
      this.currentpage=1;
      this.transactionFlag=true;
      this.pageTransactions=[];
      this.totalItems=0;
      this.getAllTransactions();

    }
    else
    {
       this.getAllOffers();
    }
    });
  }
  getTransactionTap()
    {
      this.currentpage=1;
      this.transactionFlag=true;
      this.pageTransactions=[];
      this.totalItems=0;
        this.getPage();
    }
}
