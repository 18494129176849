import { Branch } from './branch';
import { Category } from './category';

export class Partner {
  id: number;
  merchantName: string;
  logo: string;
  partnerBranches: Branch[];
  category: string;
  merchantDescription:string;
}

