import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingService } from './services/setting.service';
import { Router, NavigationStart,NavigationEnd } from '@angular/router';
import { AccountService } from './services/account.service';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';


// declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ExxonMobil';
  isOpened : boolean=false;
  constructor(
    private modalService: NgbModal,
    public settingService: SettingService,
    public router: Router,
    public accountService: AccountService,
    private _localStorageService:LocalStorageServiceEncrypt
  ) {


    router.events.forEach(event => {

      if (event instanceof NavigationStart) {
        if (event.url === '/login' || event.url === '/') {
          settingService.showLogOut = false;
        } else {
          settingService.showLogOut = true;
          if(this.accountService.isUserLoggedIn()){
            var accept=this.accountService.iSAcceptDisclaimer();
            if(accept==null)
            {
                this.settingService.currentAcceptTerms.subscribe( res=>{
                 // accept=res;
                  if(!res && !this.isOpened){
                    document.getElementById('acceptTerms').click();
                  } else if (this.isOpened && res==true){
                    this.isOpened=false;
                    this.modalService.dismissAll();
                  }
                });
            }
           else if(!accept){
            document.getElementById('acceptTerms').click();
          }
          }

        }
        window.scrollTo(0, 0);
      }

      // if (event instanceof NavigationEnd) {
      //   ga('set', 'page', event.urlAfterRedirects);
      //   ga('send', 'pageview');
      // }

    });


    

  }
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }
  openVertically(content) {
    this.isOpened=true;
    this.modalService.open(content, { centered: true,keyboard:false,backdrop:'static',backdropClass: 'dark-modal'});
  }
  exitModal()
  {
    this.isOpened=false;
    this.router.navigate(['/login']);
  }
  acceptTerms()
  {
    this.accountService.checkDisclaimer(this.settingService.user.mobileNumber,true).subscribe(res=>{
      this._localStorageService.set('AcceptTerms',res);
      this.settingService.setAcceptTerms(res);
      if(res){
        this.isOpened=false;
        this.modalService.dismissAll();
      }
    });
  }
}
