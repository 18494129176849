import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingService } from '../services/setting.service';
import { TransactionService } from '../services/transactions.service';
import { BulkCouponModel } from '../models/bulkCouponModel';
import { BulkResponseModel } from '../models/bulkResponseModel';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-voucher',
  templateUrl: './create-voucher.component.html',
  styleUrls: ['./create-voucher.component.scss']
})
export class CreateVoucherComponent implements OnInit {

  constructor(private modalService: NgbModal, public settingService: SettingService,
     private transactionService: TransactionService, public accountService: AccountService, public router: Router) { }
  voucherLst=[{value:'',valid:0},{value:'',valid:0},{value:'',valid:0},{value:'',valid:0},{value:'',valid:0}]
  submit=false;
  disableSubmit=false;
  error='';
  ngOnInit() {
    this.settingService.voucherModalOpsevable.subscribe(a=> {
      if(a==true)
      document.getElementById('SendCodes').click();
    });
   
  }
  closeResult = '';

  openVerticallyCentered(content) {
    var accept=this.accountService.iSAcceptDisclaimer();
    if(!accept){
      document.getElementById('acceptTerms').click();
      return;
    }
    this.settingService.isError = false;
    this.settingService.message='';
    this.voucherLst=[{value:'',valid:0},{value:'',valid:0},{value:'',valid:0},{value:'',valid:0},{value:'',valid:0}]
    this.submit=false;
    this.disableSubmit=false;
    this.error='';
    // this.modalService.open(content, { centered: true });
    this.modalService.open(content, {centered: true,ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  add() {
    this.voucherLst.push({value:'',valid:0});
  }

  submitCodes(burn: boolean)
  {
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message='';
    var data= this.voucherLst.filter(c =>
      c.value!='');

     var verifiedLst=this.voucherLst.filter(c =>
        c.value!=''&& c.valid==2);
        
      if(data.length==0||data==undefined)
      {
        this.settingService.loading = false;
        this.error="من فضلك ادخل علي الاقل كود واحد";
        return;
      }

    if(burn && (verifiedLst!=undefined && verifiedLst.length>0))
    {
       this.submit=false;
       this.error="من فضلك تحقق من الاكواد";
    }

    this.error=burn==true?"سوف تصلك رسالة بالنقاط المضافة الى رصيدك":"";
   if(burn){this.disableSubmit=true;}
      var vouchers= data.map(({ value }) => value.trim());
      var bulk=new BulkCouponModel();
      bulk.burn=burn;
      bulk.msisdn=this.settingService.user.mobileNumber;
      bulk.couponNumber=vouchers
      this.transactionService.burnBulkVouchers(bulk).subscribe(res=>{
        console.log(res);
        if(res.length!=0)
        {

          this.settingService.loading = false;
          this.settingService.isError = false;
          this.settingService.message='';
          var errorList: BulkResponseModel[];
          if(burn)
          {
             errorList = res.filter(c => c.burned.toString() == 'false');
          }
          else
          {
             errorList = res.filter(c => c.readyToBurn.toString() == 'false');
          }
          this.voucherLst.forEach(function(value)
          {
            
             var code=res.filter(a=>a.couponNumber==value.value && value.value!='')[0];
            
             if(code!=undefined && value.value!='')
             {
              
               value.valid=code.readyToBurn.toString()=='true' ? 1 : 2;
            
             }
             else{
               value.valid=0;
             }
          });
         if(errorList!=undefined &&errorList.length!=0)
         {
          
            this.error="من فضلك ادخل الكود صحيح";
            this.disableSubmit=false;
            this.submit=false;
         }
         else 
         {
             if(burn){ this.error="تم بنجاح ";}
             else {this.submit=true;this.error='';}
         }
        }
        else{

          this.error="حدث خطأ,من فضلك حاول فى وقت لاحق ";
          this.settingService.loading = false;
          this.settingService.isError = false;
          this.settingService.message='';
          this.submit=false;

        }
        
      });
  }
  inputChange()
  {
    this.submit=false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
