import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../services/partner.service';
import { SettingService } from '../services/setting.service';
import { Partner } from '../models/partner';
import { Router } from '@angular/router';
import { Category } from '../models/category';

@Component({
  selector: 'app-partners-details',
  templateUrl: './partners-details.component.html',
  styleUrls: ['./partners-details.component.scss']
})
export class PartnersDetailsComponent implements OnInit {
  partner = new Partner();
  categoryId: number;
 // relatedPartners: Partner[] = [];
  categoryName: string;
  constructor(
    private partnerService: PartnerService,
    public settingService: SettingService,
    private router: Router
  ) {
   // this.reload();
  }

  ngOnInit() {
        this.partner=JSON.parse(localStorage.getItem('partner'));
        if(this.partner==null || this.partner==undefined)
        {
          this.router.navigate(['partners']);
        }
  }

  // getPartnerDetails() {
  //   this.settingService.loading = true;
  //   this.partnerService
  //     .getPartnerById(this.partner.id, this.categoryId,false)
  //     .subscribe(res => {
  //       this.partner = res.partner;
  //       this.relatedPartners = res.relatedPartners;
  //       this.settingService.loading = false;
  //       console.log(res);
  //     });
  // }
  // reload() {
  //   this.settingService.languageEvent.subscribe(res => {
  //  //   this.getPartnerDetails();
  //   });
  //}
}
