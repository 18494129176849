import { DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CountryService } from './country.service';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TraderCrmService } from '../services/trader-crm.service';
import { EligibleTraderModel, SampleModel } from '../models/EligibleTraderModel';
import { SettingService } from '../services/setting.service';
import { saveAs } from "file-saver";
import { BulkFileResultModel } from '../models/BulkFileResultModel';
import { configurationModel } from '../models/configurationModel';
import { promise } from 'protractor';
import { resolve } from 'url';
import { traderConfigurationModel } from '../models/traderConfigurationModel';

@Component({
  selector: 'app-trader-crm',
  templateUrl: './trader-crm.component.html',
  styleUrls: ['./trader-crm.component.scss'],
  providers: [CountryService, DecimalPipe]
})
export class TraderCrmComponent implements OnInit{

  DeletedTraders: string[] = [];
  total: number = 0;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild('fileUpload', { static: false }) csvReader: any;
  closeResult = '';
  AllTraders: EligibleTraderModel[] = [];
  data: SampleModel[] = [];
  deletedMsisdn: string;
  Traders: EligibleTraderModel[] = [];
  totalItems: number = 0;
  currentpage: number = 1;
  uploadProgress: number;
  uploadSub: Subscription;
  DisplayName = '';
  validFile = true;
  loading = false;
  bulkResponse = new BulkFileResultModel();
  errorMessage = '';
  fileName = '';
  isChecked = {};
  DeletedBulkTraders: string;
  pageNumber: number = 1;
  page: number = 1;
  pageSize: number = 10;
  traderConfigurationData : traderConfigurationModel= new traderConfigurationModel();
  errorBonus = '';
  global = '';
  headerValid = true;
  configs: configurationModel[];

  constructor(public service: CountryService, private toastr: ToastrService, private modalService: NgbModal, private traderCRMService: TraderCrmService, public settingService: SettingService) {
    this.settingService.pageName='trader-crm';

  }
  ngOnInit() {
        this.getAllEligibleTrader('');

}
  loadPage(page: number) {
    this.pageNumber = page;
    this.getAllEligibleTrader('');
  }
  //only number will be add
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateBonus(event: any) {
    const pattern = /^[0-9]*$/;
    let value = event.target.value;
    if (!value.match(pattern)) {
      this.errorBonus = "Numbers Only";
    }
    else if (Number(value) < 0 || Number(value) > 99999) {
      this.errorBonus = "Bonus Value should be between 0 and 99999";
    }
    else {
      this.errorBonus = "";
    }
  }

  FindMsisdn(term: string) {
    if (term.length == 11) {
      this.pageNumber = 1;
      this.getAllEligibleTrader(term);
    } else if (!term) {
      this.getAllEligibleTrader('');
    }
  }

  getAllEligibleTrader(searchTerm) {
    this.settingService.loading = true;
    this.total = 0
    this.traderCRMService.getAllTrader(this.pageNumber, this.pageSize, searchTerm).subscribe(res => {
      this.Traders = res.data;
      this.total = res.totalItems;
      this.isChecked = {};
      this.settingService.loading = false;
    }
    );
  }

  onFileSelected(sucessContent, event, errorContent) {
    this.settingService.loading = true;
    this.loading = true
    const file: File = event.target.files[0];
    if (file) {
      this.validateFile(file).then((data)=>{
       if(data){
        this.fileName = file.name;
        const uploadedFile = new FormData();
        uploadedFile.append('file', new Blob([file], { type: 'test/csv' }), file.name);
        this.traderCRMService.uploadBulkFile(uploadedFile).subscribe(res => {
          if (res) {
            this.modalService.open(sucessContent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
            this.fileName = '';
            this.bulkResponse = res;
          }
          this.getAllEligibleTrader("");
          this.loading = false;
        }, error => {
          console.log(error);
          if (error.status = 401) this.errorMessage = "Un Authorized Request , please check and try again ";
          if (error.status = 400) this.errorMessage = error.error.message + ',' + error.statusText;
          this.fileName = '';
          this.modalService.open(errorContent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });

        });
        this.settingService.loading = false;
      }
       else{
        this.modalService.open(errorContent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          console.log("result" + reason);
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.fileName = "";
        this.settingService.loading = false;
        this.loading = false
        return;
       }
      });
    }
  }
     
  async validateFile(file: File) {
    this.global = "here 1";
    this.validFile = true;
    await this.validateFileHeaders(file).then(data=>{
        this.headerValid = data;
    });
    console.log(this.headerValid);
    if (file.name.split('.').pop() != 'csv') {
      this.errorMessage = "Wrong File Format";
      this.validFile = false;
    }
    else if (file.size > 20000000) {
      this.errorMessage = "File Size Exceeds Limit";
      this.validFile = false;
    }
    else if (!this.headerValid){
      this.errorMessage = "Header Not Match";
      this.validFile = false;
    }
    return this.validFile;
  }
  async validateFileHeaders(file: File): Promise<boolean> {
    let reader = new FileReader();
    reader.readAsText(file);
    return new Promise((resolve,reject)=>{ reader.onload = async () => {
      let csvData = reader.result;
      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
      let headersRow = this.getHeaderArray(csvRecordsArray);
      if (headersRow.length == 1 && headersRow.includes('MSISDN')) {
        await resolve(true);
      }
      else{
        await resolve(false);
      };
    }});
  }
  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  checkTrader(item: EligibleTraderModel, i: number) {
    this.isChecked[i] = !this.isChecked[i];
    this.isChecked[i] ? this.DeletedTraders.push(item.msisdn) : (this.DeletedTraders = this.DeletedTraders.filter(t => t != item.msisdn));
    this.DeletedBulkTraders = this.DeletedTraders.join(',');

  }
  openConFirmDelete(deleteContent, msisdn) {
    this.deletedMsisdn = msisdn;
    this.modalService.open(deleteContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  DeleteTrader(msisdn) {
    this.traderCRMService.DeleteTrader(msisdn).subscribe(res => {
      if (res) {
        this.getAllEligibleTrader('');
        this.toastr.success("", msisdn + "Delete Successfully");
        this.modalService.dismissAll();
      }
    })

  }
  showConfigurations(configurationConent, errorContent) {
    this.traderCRMService.GetConfigs().subscribe(res => {
      if (res) {
        this.configs = res;

          if(this.configs.find(c=> c.key == "AllowTraderInsertConsumerKYC") != undefined){
            this.traderConfigurationData.allowTraderInsertConsumerKYC  =  Boolean(this.configs.find(c=> c.key == "AllowTraderInsertConsumerKYC").value.toLowerCase() =='true');
          }
          else{
            this.traderConfigurationData.allowTraderInsertConsumerKYC  = false;
            this.configs.push({key : "AllowTraderInsertConsumerKYC",value :'false'});
          }

          if(this.configs.find(c=> c.key == "AllowTraderToGetConsumerKycBonus") != undefined){
            this.traderConfigurationData.allowTraderToGetConsumerKycBonus  =  Boolean(this.configs.find(c=> c.key == "AllowTraderToGetConsumerKycBonus").value.toLowerCase() =='true');
          }
          else{
            this.traderConfigurationData.allowTraderToGetConsumerKycBonus  = false;
            this.configs.push({key:  "AllowTraderToGetConsumerKycBonus",value :  'false'});
          }

          if(this.configs.find(c=> c.key == "TraderBonusPerConsumer") != undefined){
            this.traderConfigurationData.traderBonusPerConsumer  =  Number(this.configs.find(c=> c.key == "TraderBonusPerConsumer").value);
          }
          else{
            this.traderConfigurationData.traderBonusPerConsumer  = 0;
            this.configs.push({key :  "TraderBonusPerConsumer",value : '0'});
          }
        
        this.modalService.open(configurationConent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }, error => {
      this.modalService.open(errorContent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      this.errorMessage = error.error.message;
    });
  }

  updateConfigurations(errorContent) {

    this.configs.forEach(element => {
      if(element.key == "AllowTraderInsertConsumerKYC")
         element.value = String(this.traderConfigurationData.allowTraderInsertConsumerKYC);
      else if(element.key == "AllowTraderToGetConsumerKycBonus")
         element.value = String(this.traderConfigurationData.allowTraderToGetConsumerKycBonus);
      else if(element.key == "TraderBonusPerConsumer")
         element.value = this.traderConfigurationData.traderBonusPerConsumer.toString();   
    });
    this.traderCRMService.updateConfigurations(this.configs).subscribe(res => {
      if (res) {
        this.modalService.dismissAll();
      }
    }, error => {
      this.modalService.open(errorContent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      this.errorMessage = error.error.message;
    });
  }

  downloadCSV(): void {
    this.data.push({ MSISDN: '' });
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(this.data[0]);
    let csv = this.data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, "Sample.csv");
    this.toastr.success('', 'Sample downloaded successfully');

  }

  DownloadResult(resultFileName) {
    this.traderCRMService.DownloadResultFile(resultFileName).subscribe(response => {
      let fileName = response.headers.get('x-fileName');
      let blob: Blob = response.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
}
