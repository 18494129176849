import { Component, OnInit } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { AccountService } from '../services/account.service';
import { RegisterModel } from '../models/registerModel';
import { Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-foregt-password',
  templateUrl: './foregt-password.component.html',
  styleUrls: ['./foregt-password.component.scss']
})
export class ForegtPasswordComponent implements OnInit {

  constructor(public settingService: SettingService, public accountService: AccountService, public router: Router) { }
  active = 1;
  mobileNumber: string;
  otp: string = "";
  resetpassword: RegisterModel = new RegisterModel();
  token = "";
  ngOnInit() {
    this.settingService.isError = false;
    this.settingService.message = null;
  }
  verifyMobileNumber(mobileNumber) {
    this.settingService.isError = false;
    this.settingService.message = '';
    this.settingService.loading = true;
    this.accountService.ForgetPassword(mobileNumber).subscribe(res => {
      this.mobileNumber = mobileNumber;
      this.settingService.loading = false;
      this.settingService.isError = false;
      this.active = 2;
    });

  }
  forgetPasswordVerifyOtp() {
    if (this.otp.length < 4) {
      return;
    }
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.accountService.ForgetPasswordVerifyOtp(this.mobileNumber, this.otp).subscribe(res => {
      if (res.errorCode == 0) {
        this.active = 3;
        this.token = res.token;
        this.settingService.isError = false;
        this.settingService.loading = false;
      }
      else {
        this.settingService.isError = true;
        this.settingService.message = res.errorDesc;
        this.settingService.loading = false;
      }

    });
  }
  setPassword() {
    this.settingService.loading = true;
    this.settingService.isError = false;
    this.settingService.message = '';
    this.resetpassword.mobileNumber = this.mobileNumber;
    this.resetpassword.token = this.token;
    this.accountService.SetPassword(this.resetpassword).subscribe(res => {
      if (res.errorCode == 0) {
        this.settingService.loading = false;
        this.settingService.isError = false;
        this.router.navigate(['login']);
      } else {
        this.settingService.isError = false;
        this.settingService.message = res.errorDesc;
      }

    });
  }

  onOtpChange(event) {
    this.otp = event;
  }

  onNavChanged(changeEvent: NgbNavChangeEvent){
    this.settingService.isError = false;
    this.settingService.message = null;
  }
}
