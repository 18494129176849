import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ValidationResponse } from '../models/validationResponse';
import { RegisterModel } from '../models/registerModel';
import { LogInModel } from '../models/logInModel';
import { ChangePasswordModel } from '../models/changePasswordModel';
import { UpdateProfileModel } from '../models/updateProfileModel';
import { LocalStorageServiceEncrypt } from 'angular-2-local-storage-encrypt';
import { SettingService } from './setting.service';
import { city } from '../models/CitiesModel';
import { ListData } from '../models/ListData';

@Injectable({
  providedIn: 'root'
})

export class AccountService extends BaseService {
  apiUrl = '/api/register';

  constructor(private httpClient: HttpClient,private _localStorageService:LocalStorageServiceEncrypt, private settingService:SettingService) {
    super();
  }
  loadCities() {
    const CitiesFile = `assets/CitiesList/Cities.json`;

    return this.httpClient.get<city[]>( CitiesFile);
    }

  validateMobileNumber(mobileNumber: string) {
    return this.httpClient.get<ValidationResponse>(
      this.baseUrl + this.apiUrl + '/ValidateMobileNumber?mobileNumber=' + mobileNumber+'&lang=ar-EG',
      this.getAuthHeader()
    );
  }
  verifyOtp(mobileNumber: string, otp: string) {
    return this.httpClient.get<ValidationResponse>(
      this.baseUrl +
        this.apiUrl +
        '/VerifyOtp?mobileNumber=' +
        mobileNumber +
        '&otp=' +
        otp,
      this.getAuthHeader()
    );
  }
  createAccount(register: RegisterModel) {
    return this.httpClient.post<ValidationResponse>(
      this.baseUrl +
        this.apiUrl +'/CreateAccount',register,
      this.getAuthHeader()
    );
  }
  isUserLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }
  signOut() {
    localStorage.clear();
  }
  getData(mobileNumber: string, otp: string) {
    return this.httpClient.get<ValidationResponse>(
      this.baseUrl +
        '/api/account' +
        '/VerifyOtp?mobileNumber=' +
        mobileNumber +
        '&otp=' +
        otp,
      this.getAuthHeader()
    );
  }
  logIn(logIn: LogInModel)
  {
    return this.httpClient.post<any>(
      this.baseUrl +
        this.apiUrl +
        '/LogIn',logIn,
      this.getAuthHeader()
    );
  }
  getUserMobileNumber(): string {
    return localStorage.getItem('mobileNumber');
  }

  //forgetPassword
  ForgetPassword(mobileNumber: string) {
    return this.httpClient.get<ValidationResponse>(
      this.baseUrl + this.apiUrl + '/ForgetPassword?mobileNumber=' + mobileNumber,
      this.getAuthHeader()
    );
  }
  ForgetPasswordVerifyOtp(mobileNumber: string, otp: string) {
    return this.httpClient.get<ValidationResponse>(
      this.baseUrl +
        this.apiUrl +
        '/ForgetPasswordVerifyOtp?mobileNumber=' +
        mobileNumber +
        '&otp=' +
        otp,
      this.getAuthHeader()
    );
  }
  SetPassword(setPassword: RegisterModel) {
    return this.httpClient.post<ValidationResponse>(
      this.baseUrl +
        this.apiUrl +'/SetPassword',setPassword,
      this.getAuthHeader()
    );
  }
  changePassword(changePassword: ChangePasswordModel)
  {
    return this.httpClient.post<ValidationResponse>(
      this.baseUrl +
        this.apiUrl +'/ChangePassword',changePassword,
      this.getAuthHeader()
    );
  }
  updateProfile(profile: UpdateProfileModel)
  {
    return this.httpClient.post<ValidationResponse>(
      this.baseUrl +
        this.apiUrl +'/UpdatePortalProfile',profile,
      this.getAuthHeader()
    );
  }

  getProfileData(mobileNumber: string) {
    return this.httpClient.get<UpdateProfileModel>(
      this.baseUrl +this.apiUrl+
        '/ProfileData?mobileNumber=' +
        mobileNumber ,
      this.getAuthHeader()
    );
  }

  checkDisclaimer(userName:string,acceptTerms: boolean)
  {
    return this.httpClient.get<boolean>(
      this.baseUrl +
      '/api/account/CheckDisclaimer?userName=' +
        userName +
        '&acceptTerms=' +
        acceptTerms,
      this.getAuthHeader()
    );
  }
  iSAcceptDisclaimer(): boolean {
    var accept=this._localStorageService.get('AcceptTerms') as boolean;
    return accept;
  }

  GetCitiesList() {
    return this.httpClient.get<ListData[]>(
      this.baseUrl +
        '/api/account/GetCitiesList',
      this.getAuthHeader()
    );
  }

  
  GetAreasByCityId(id:number) {
    return this.httpClient.get<ListData[]>(
      this.baseUrl +
        '/api/account/GetAreasByCityId?id='+id,
      this.getAuthHeader()
    );
  }

  deleteAccount(registerToken) {
    return this.httpClient.get<ValidationResponse>(
      this.baseUrl + this.apiUrl +'/DeleteAccount?registerToken='+registerToken,
      this.getAuthHeader()
    );
  }

  
}
