import { Component, OnInit } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { User } from '../models/user';
import { WalletSummaryModel } from '../models/walletSummaryModel';
import { TierService } from '../services/tier.service';
import { TierModel } from '../models/tierModel';
declare var $: any;


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  constructor(
    public settingService: SettingService,
    public router: Router,
    public accountService: AccountService,
    private tierService: TierService
  ) {}
  walletSummary: WalletSummaryModel=new WalletSummaryModel();
  closeResult: string;
  tiersLst: TierModel[]=[];
  tillPoints: number=0;
  tillTier:string="";
  ngOnInit() {
    this.settingService.getUserInfoFromToken(localStorage.token);
    this.getSummary();

    $(document).ready(function () {

      var navTrigger = document.getElementsByClassName('nav-trigger')[0],
        body = document.getElementsByTagName('body')[0];

      navTrigger.addEventListener('click', toggleNavigation);

      function toggleNavigation(event) {
        event.preventDefault();
        body.classList.toggle('nav-open');
      }

      // $(".nav li a").click(function () {
      //   $('body').removeClass('nav-open');
      // });

      $(window).scroll(function () {
        if ($(window).scrollTop() >= 26) {
          $('.text-balance').addClass('fixed-header');
          $('.nav-fixed').addClass('nav-fixed-2');
          // $('nav div').addClass('visible-title');
        }
        else {
          $('.text-balance').removeClass('fixed-header');
          $('.main-content').addClass('fixed-header2');
          $('.nav-fixed').removeClass('nav-fixed-2');
          // $('nav div').removeClass('visible-title');
        }
      });
    });

  }
  changeLanguage(lang) {
    this.settingService.useLanguage(lang);
  }
  signOut() {
    const lang = localStorage.getItem('lang');
    this.accountService.signOut();
    this.settingService.user=new User();
    localStorage.lang = lang;
    this.router.navigate(['/login']);
  }

  openVouchersModal()
  {
     this.settingService.openVoucherModal(true);
  }
  
  getSummary()
  {
    this.settingService.walletSummary.subscribe(res=>{
       this.walletSummary=res;
    });
    if(this.walletSummary.totalPoints==undefined){
      this.tierService.getWalletSammary(this.settingService.user.mobileNumber).subscribe(res=>{
        localStorage.walletId=res.walletId;
         this.walletSummary=res;
        this.settingService.setWalletSummary(res);
        
      });
    }
    
  }
}
