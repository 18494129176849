import { Component, OnInit } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { Partner } from '../models/partner';
import { PartnerService } from '../services/partner.service';
import { Category } from '../models/category';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  allPartners: Partner[] = [];
  pagePartners: Partner[] = [];
  categories: string[] = [];
  partner:Partner;
  pages = 0;
  currentPage = 1;
  numberOfElements = 12;
  selectedCategory: any = '';
  totalItems = 0;
  search = '';
  constructor(
    public settingService: SettingService,
    private partnerService: PartnerService,
    private modalService: NgbModal
  ) {
    this.settingService.pageName='partners';
  }

  ngOnInit() {

    this.getAll();
    //this.getCategories();
    this.reload();
  }
  getAll() {
    this.settingService.loading = true;
    this.partnerService.getAll().subscribe(res => {
      this.allPartners = res;
      this.totalItems = res.length;
    const distinctThings = this.allPartners.filter(
      (a, i, arr) => arr.findIndex(t => t.category === a.category) === i
    );

      this.categories= distinctThings.map(({ category }) => category);

      this.pagePartners = this.allPartners.slice(0, this.numberOfElements);
      this.getPage();
      this.settingService.loading = false;
    });
  }
  // getCategories() {
  //   this.partnerService
  //     .getCategories()
  //     .subscribe(res => {(this.categories = res);
  //       console.log(res);
  //     });
  // }
  reset() {
    if (this.selectedCategory) {
      this.pagePartners = this.allPartners.filter(
        c => c.category === this.selectedCategory
      );
      this.totalItems = this.pagePartners.length;
      this.pagePartners = this.pagePartners.slice(0, this.numberOfElements);
    } else {
      this.totalItems = this.allPartners.length;
      this.pagePartners = this.allPartners.slice(0, this.numberOfElements);
    }
    this.getPage();
  }
  findPartner(name: string) {
    if (!name) {
      this.search = name;
      this.reset();
      return;
    }
    this.search = name;
    if (!this.selectedCategory) {
      this.pagePartners = this.allPartners;
    }
    this.pagePartners = this.pagePartners.filter(c =>
      c.merchantName.toLowerCase().includes(name.toLocaleLowerCase())
    );
    this.totalItems = this.pagePartners.length;
    this.pagePartners = this.pagePartners.slice(0, this.numberOfElements);
    this.getPage();
  }
  getPage() {
    this.pages = this.pagePartners.length / this.numberOfElements;
    this.currentPage = 1;
  }
  getPartnerByCategory(categoryId: any) {
    if (!categoryId) {
      this.selectedCategory = '';
      this.reset();
      return;
    }
    this.selectedCategory = categoryId.toString();

    this.pagePartners = this.allPartners.filter(
      c => c.category === categoryId
    );
    if (this.search) {
      this.pagePartners = this.pagePartners.filter(c =>
        c.merchantName.toLowerCase().includes(this.search.toLowerCase())
      );
    }
    this.totalItems = this.pagePartners.length;
    this.pagePartners = this.pagePartners.slice(0, this.numberOfElements);
    this.getPage();
  }
  getMorePartners() {
    this.currentPage++;
    this.pagePartners = this.allPartners;
    if (this.selectedCategory) {
      this.pagePartners = this.allPartners.filter(
        c => c.category === this.selectedCategory
      );
    }
    if (this.search) {
      this.pagePartners = this.pagePartners.filter(c =>
        c.merchantName.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      );
    }
    this.pagePartners = this.pagePartners.slice(
      0,
      this.currentPage * this.numberOfElements
    );
  }
  reload() {
    this.settingService.languageEvent.subscribe(res => {
      this.getAll();
     // this.getCategories();
    });
  }
  loadDetails(event)
  {
    this.partner=event;
   // localStorage.partner=JSON.stringify(event);
  //   console.log(event);
  //  var cat= this.categories.filter(c =>
  //     c == event.category.name)[0];
  //   this.settingService.changeCategory(cat);
  }


  closeResult = '';
  
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



}
