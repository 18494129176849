import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AccountService } from "../account.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}
  canActivate() {
    if (this.accountService.isUserLoggedIn()) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
